<template>
    <div class="section">
        <div class="section-all">
            <div class="about-left">
                <div class="about-title">
                    <span class="size36">企业简介</span>
                    <span>Company Profile</span>
                </div>
            </div>
            <div class="about-right" :class="about">
                <div class="about-right__all">
                    <div class="about-right__title">
                        <span class="size36">和行约车</span>
                        <span class="size18">品质生活·智慧和行</span>
                    </div>
                    <p class="about-right__p size20" >
                        &emsp;合肥和行科技有限公司（简称“和行科技公司”）是安徽江淮汽车集团股份有限公司的全资子公司，是集团公司下属汽车出行、汽车物流和汽车金融等汽车服务板块的核心企业之一。公司集网约车、出租车、汽车租赁三大业务于一体。<br />
                        &emsp;旗下“和行约车”是和行科技公司开发运营的网约车平台，自2019年1月9日上线以来，和行约车始终秉承“安全合规、统一车辆、专职司机、拒绝溢价”的经营理念，为用户提供安全、快捷、舒适、绿色的一站式出行解决方案。<br />
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
@import url('../../../static/css/home.less');
@import url('../../../static/css/media.less');
</style>